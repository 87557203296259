import { Swiper } from "swiper";
import { Navigation, Pagination, A11y } from "swiper/modules";
import "swiper/css";

document.addEventListener("DOMContentLoaded", function () {
	const gridSlider = document.querySelectorAll("section.grid");
	gridSlider.forEach((slider) => {
		if (slider.querySelector(".swiper")) {
			const sliderWrapper = slider.querySelector(".swiper");
			const gridSliderSingle = new Swiper(sliderWrapper, {
				modules: [Navigation, Pagination, A11y],
				a11y: true,
				spaceBetween: 8,
				centeredSlides: false,
				loop: true,
				allowTouchMove: true,
				slidesPerView: 3,
				pagination: {
					el: ".swiper-pagination", 
					clickable: true,
				},
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				},
				keyboard: {
					enabled: true,
					onlyInViewport: true,
				},
				breakpoints: {
					768: {
						spaceBetween: 32,
					},
				},
			});
		}
	});
});
